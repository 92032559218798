<template>
  <div class="main-container">
    <Header />

    <!--<TopCarousel :sliders="slider.MallLikeU" />-->

    <!--<AboutSectionFour :data="malllikeu.about" :title="'¿Qué es MallLikeU?'" />-->

    <!--Our clients section-->
    <!--<LatestProject :data="malllikeu.owrClients" :title="'Nuestros Clientes'" />-->

    <!--<div>
      <img
        src="/assets/img/banners/payforu/f3.jpg"
        style="background-size: cover;width: -webkit-fill-available;"
      />
    </div>-->

    <!--Uses and Implementatios section-->
    <!--<ProjectGridSlider
      :data="malllikeu.implementations"
      :title="'Usos e Implementaciones'"
    />-->

    <!--Advantage-->
    <!--<div class="page-wrapper section-space--inner--60">
      <div class="service-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="service-details">
                <div class="content section-space--top--30">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="team-job__content-wrapper">
                        <h2 style="padding-bottom: 20px;">
                          Ventajas competitivas
                        </h2>
                        <div class="team-job__list-wrapper">
                          <div class="team-job__single">
                            <h3 class="title">
                              Marketplace orientado al servicio al cliente
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Está conformado por 180 subcategorías para ubicar
                              fácilmente productos y servicios. Es una
                              plataforma configurable, dinámico, versátil y
                              elegante.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Opciones y herramientas que facilitan tu
                              experiencia
                            </h3>
                            <p class="text" style="text-align: justify;">
                              PayForU como Botón de pago propio.<br />
                              Contrataciones dinámicas de avisos y
                              publicidad.<br />
                              Busca por mí.<br />
                              B2B/B2C Privado.<br />
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Servicios de atención personalizada
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Soporte MallLikeU y estrategias de Marketing
                              Digital orientados a la generar impacto y buenas
                              experiencias en todos los actores del ecosistema.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Red de Franquiciados
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Sistema de Franquicias bajo la figura de
                              Representantes a nivel nacional, capacitados y
                              comprometidos en potenciar su modelo de negocio a
                              través de la gestión y soporte de afiliados en el
                              Marketplace.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <img
                          src="/assets/img/service/Tienda-virtual-en-MallLikeU.jpg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <!--<div>
      <img
        src="/assets/img/banners/malllikeu/2.png"
        style="background-size: cover;width: -webkit-fill-available;"
      />
    </div>-->

    <div
      class="breadcrumb-area bg-img"
      style="background-image: url(assets/img/banners/payforu/f3.jpg);width: -webkit-fill-available;height: 80px;"
    >
      <div class="container">
        <div class="row">
          <div class="col-8">
            <h2 class="text-center" style="color:#fff;">
              Encuentra lo que buscas en MallLikeU
            </h2>
          </div>
          <div class="col-4">
            <a href="htts://malllikeu.com">
              <button
                class="btn ht-btn--round"
                style="background-color: #003d74;"
              >
                Comprar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="about-section section-space--inner--60">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold;">
              Preguntas frecuentes
            </h2>
          </div>
          <div class="col-lg-6 col-12 mt-30">
            <div v-for="item in malllikeu.FAQ" :key="item.id">
              <Accordion :question="item.question" :answer="item.answer" />
            </div>
          </div>
          <div class="col-lg-6 col-12 mb-30">
            <ContactForm :platform="18" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-section section-space--inner--20">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold;">
              Conoce nuestro Ecosistema Digital
            </h2>
          </div>
        </div>
      </div>
    </div>

    <Carousel2 :data="malllikeu.conoceMas" />

    <BrandCarousel addClass="grey-bg" />

    <Footer />-->

    <!--<OffCanvasMobileMenu />-->

    <!-- WhatsApp Bottom -->
    <!--<div id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios."
        title="Escríbenos por Whastapp"
        data-tap="tooltip"
      >
        <i
          class="fa fa-whatsapp"
          style="color: #ffffff;"
          aria-hidden="true"
        ></i>
      </a>
    </div>-->

    <!-- back to top start -->
    <!--<back-to-top class="scroll-top" bottom="100px" right="10px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>-->
    <!-- back to top end -->
  </div>
</template>

<script>
import slider from "../data/slider.json";
import data from "../data/service.json";
import malllikeu from "../data/malllikeu.json";
import Header from "@/components/Header";
import AboutSectionFour from "../components/sections/AboutSectionFour";
import HeroTwo from "../components/sections/HeroTwo";
import Carousel1 from "../components/sections/Carousel1";
import TopCarousel from "../components/TopCarousel";
import Carousel2 from "../components/sections/Carousel2";
import ProjectGridSlider from "@/components/sections/ProjectGridSlider";
import Breadcrumb from "../components/Breadcrumb";
import TeamMember from "../components/sections/TeamMember";
import LatestProject from "../components/sections/LatestProject";
import Accordion from "../components/Accordion";
import ContactForm from "../components/ContactForm";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import CarouselPlanSection2 from "@/components/sections/CarouselPlanSection2";

export default {
  components: {
    Header,
    HeroTwo,
    LatestProject,
    Breadcrumb,
    Accordion,
    ContactForm,
    TopCarousel,
    ServiceDetailsMain,
    ProjectGridSlider,
    TeamMember,
    AboutSectionFour,
    ServiceDetailsSidebar,
    Carousel1,
    Carousel2,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CarouselPlanSection2
  },
  data() {
    return {
      config: {
        headers: {
          Authorization: ""
        }
      },
      data,
      slider,
      malllikeu,
      orderData: "",
      user_id: ""
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.breadcrumb-area {
  min-height: 0px;
}
.swiper-wrapper {
  padding: 0px !important;
}
.login-form {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}
.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}
#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}
@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }
  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
</style>
